import { Databases, ID, Query } from 'appwrite';
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import appwriteClient from '../appwrite.config'
import 'react-toastify/dist/ReactToastify.css'
import { IoIosAddCircleOutline } from "react-icons/io";
import { VscDiscard } from "react-icons/vsc";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';

const TripPanelCard = (props) => {

    const navigate = useNavigate();
    

    const [driverDropdown,setDriverDropdown]=useState([
        {
            driverName:"Select Driver"
        },
        {
            driverName:"Panchu_Driver"
        },
        {
            driverName:"Pradeep_Driver"
        }
    ]);
    const [vehicleDropdown,setVehicleDropdown]=useState([
        {
            vehicleRegNo:"Select Vehicle"
        },
        
    ])
const [addedPrnos,setAddedPrnos]=useState([
    
]);
const [addedInvoices,setAddedInvoices]=useState([
    
]);
const [addedPrnosId,setAddedPrnosId]=useState([]);
const [addedInvoicesId,setAddedInvoicesId]=useState([]);
const [addedWorkOrders,setAddedWorkOrders]=useState([]);

const [prnos,setPrnos]=useState([]);
const [workorderData,setWorkOrderData]=useState([]);
const [invoices,setInvoices]=useState([]);
const [tripNumber,setTripNumber]=useState();
const [tripName,setTripName]=useState("");
const [tripDate,setTripDate]=useState("");
const [tripTime,setTripTime]=useState("");
const [driver,setDriver]=useState("");
const [vehicle,setVehicle]=useState("");
const [status,setStatus]=useState("TripCreated");
const [createdBy,setCreatedBy]=useState("");
const [createdAt,setCreatedAt]=useState(new Date().toLocaleString());


const [loading,setLoading]=useState(false);

const databases = new Databases(appwriteClient);

useEffect(()=>{
setCreatedBy(Cookies.get("userName"));

},[])


useEffect(()=>{
  setLoading(true)
  databases.listDocuments(
    'AppwriteCloudFEApp',
    'PickupRequest',
    [
      !props.isUpdation?Query.equal("status",["PickupRequested"]):Query.equal("status",["PickupRequested","TripAlloted","TripStarted"]),
      Query.orderDesc('pickupRequestNumber'), 
    
    ]
  ).then((response)=>{
    setLoading(false);
   setPrnos(response.documents)
   
  }).catch((error)=>{
    toast.error("Error fetching data, Please try again later !!");
    console.log(error);
    setLoading(false)
  })
},[])

useEffect(()=>{
  setLoading(true)
  databases.listDocuments(
    'AppwriteCloudFEApp',
    'WorkOrder',
    [
      Query.equal("status","Initiated"),
      Query.orderDesc("$createdAt")
    ]
  ).then((response)=>{
    setLoading(false);
    setWorkOrderData(response.documents)
   
  }).catch((error)=>{
    toast.error("Error fetching data, Please try again later !!");
    console.log(error);
    setLoading(false)
  })
},[])

useEffect(()=>{
  setLoading(true)
  databases.listDocuments(
    'AppwriteCloudFEApp',
    'Invoice',
    [
      props.isUpdation?Query.equal("Status",["InvoiceInitiated","TripAlloted","TripStarted"]):Query.equal("Status","InvoiceInitiated"),
      Query.orderDesc('invNumber'), 
    
    ]
  ).then((response)=>{
    setLoading(false);
   setInvoices(response.documents)
   
  }).catch((error)=>{
    toast.error("Error fetching data, Please try again later !!");
    console.log(error);
    setLoading(false)
  })
},[])


useEffect(()=>{
  if(props.isUpdation){
 databases.getDocument(
  'AppwriteCloudFEApp',
  'TyreDetail',
  props.id,
).then((response)=>{
  setTripNumber(response.tripNumber);
  setTripName(response.tripName);
  setTripDate(response.tripDate);
  setTripTime(response.tripTime);
  setDriver(response.driver);
  setVehicle(response.vehicle);
  setStatus(response.status);
  setAddedPrnos(response.prnos);
  setAddedInvoices(response.invoices);
  setAddedWorkOrders(response.workorders)

response.prnos.forEach(element => {
    document.getElementById(element).style.backgroundColor="#f7c2b0";document.getElementById("addBtn"+element).style.visibility="hidden";document.getElementById("removeBtn"+element).style.visibility="visible"
  })  
  response.workorders.forEach(element => {
    document.getElementById(element).style.backgroundColor="#f7c2b0";document.getElementById("addBtn"+element).style.visibility="hidden";document.getElementById("removeBtn"+element).style.visibility="visible"
  })  
 
  response.invoices.forEach(element=>{
    document.getElementById(element).style.backgroundColor="#f7c2b0";document.getElementById("addBtn"+element).style.visibility="hidden";document.getElementById("removeBtn"+element).style.visibility="visible"
  })


}).catch((error)=>{
  toast.error("An error occurred while fetching Trip Data, Please try again !!")
  console.log(error)
})
}
},[])


  useEffect(()=>{
    if(!props.isUpdation){
    databases.getDocument(
        'AppwriteCloudFEApp',
        'SerialNumberRegister',
        '6633aa910027bd6103ea'
    ).then((response)=>{
        setTripNumber(response.serialNumber)
    }).catch((error)=>{
        toast.error("Unable to get Trip Number, Please try again !!")
        console.log(error);
    })
  }},[])

  useEffect(()=>{
    databases.listDocuments(
        'AppwriteCloudFEApp',
        '65ea94930453c4b21368'
    ).then((response)=>{
        setVehicleDropdown(response.documents)
    }).catch((error)=>{
        toast.error("Unable to get Vehicle Dropdown, Please try again !!")
        console.log(error);
    })
  },[])

  const handleSubmit = async() => {
    if(props.isUpdation){
if(tripNumber && createdBy && createdAt && tripName){
       await databases.updateDocument(
            'AppwriteCloudFEApp',
      'TyreDetail',
      props.id,
      {
        tripNumber:tripNumber,
        tripName:tripName,
        tripDate:tripDate,
        tripTime:tripTime,
        driver:driver,
        vehicle:vehicle,
        status:status,
        createdBy:createdBy,
        createdAt:createdAt.toString,
        prnos:addedPrnos,
        invoices:addedInvoices,
        workorders:addedWorkOrders
        
      }
        ).then(async(response)=>{
          addedWorkOrders.map((wono)=>{
            databases.updateDocument('AppwriteCloudFEApp',"WorkOrder",wono,{driver:driver.toString,vehicle:vehicle,date:tripDate.replace("-","/").replace("-","/"),time:tripTime}).then((response)=>{

            }).catch((error)=>{
              toast.error("Error updating Work Order, Please try again later");
              console.log(error)
            })
          })
          addedPrnosId.map((prno)=>{
            console.log(prno)
            databases.updateDocument('AppwriteCloudFEApp',"PickupRequest",prno,{status:"TripAlloted"}).then((response)=>{

            }).catch((error)=>{
              toast.error("Error updating Pickup Request, Please try again later");
              console.log(error)
            })
          })
          addedInvoicesId.map((inv)=>{
            databases.updateDocument('AppwriteCloudFEApp',
            'Invoice',inv,{Status:"TripAlloted"}).then((response)=>{
              
              console.log("sucess")
            }).catch((error)=>{console.log(error);
            toast.error("Error updating Invoice, Please try again later !!")
            })
          })
        toast.success("Trip Created Successfully !!");
        if(status=="TripAlloted"){
         await axios.get(`https://panel.rapiwha.com/send_message.php?apikey=F6GM19T30HLHXGO2MEJL&number=91${driver==="Pradeep_Driver"?9109101363:9109101362}&text=Hey ${driver}, Trip ${tripNumber+" :: "+tripName} is alloted to you. Check your Trip Panel Dashboard`).then(function (response) {
            toast.success("Message sent sucessfully !!")
          }).catch(function (error){
              toast.error('An error occurred while sendind Whatsapp Message !!')
              console.log(error)
          })
        }
        window.location.reload();
        }).catch((error)=>{
        toast.error("Error creating Trip, Please try again later !!");
        console.log(error);
        })
        }
        else{
        toast.error("Fields are empty, Please try again !!");
        }
    }
    else{
    if(tripNumber && createdBy && createdAt && tripName){
       await databases.createDocument(
            'AppwriteCloudFEApp',
      'TyreDetail',
      ID.unique(),
      {
        tripNumber:tripNumber,
        tripName:tripName,
        tripDate:tripDate,
        tripTime:tripTime,
        driver:driver,
        vehicle:vehicle,
    status:status,
        createdBy:createdBy,
        createdAt:createdAt.toString(),
        prnos:addedPrnos,
        invoices:addedInvoices,
        workorders:addedWorkOrders
      }
        ).then(async(response)=>{
           await databases.updateDocument(
                'AppwriteCloudFEApp',
                'SerialNumberRegister',
                '6633aa910027bd6103ea',
                {
                  type:"tripNumber",
                  serialNumber:(parseInt(tripNumber)+1)  
                }
            ).then(async(response)=>{
              addedWorkOrders.map((wono)=>{
                databases.updateDocument('AppwriteCloudFEApp',"WorkOrder",wono,{driver:driver.toString,vehicle:vehicle,date:tripDate.replace("-","/").replace("-","/"),time:tripTime}).then((response)=>{
    
                }).catch((error)=>{
                  toast.error("Error updating Work Order, Please try again later");
                  console.log(error)
                })
              })
              addedPrnosId.map((prno)=>{
                console.log(prno)
                databases.updateDocument('AppwriteCloudFEApp',"PickupRequest",prno,{status:"TripAlloted"}).then((response)=>{
console.log("sucess")

                }).catch((error)=>{
                  toast.error("Error updating Pickup Request, Please try again later");
                  console.log(error)
                })
              })
              addedInvoicesId.map(async(inv)=>{
               await databases.updateDocument('AppwriteCloudFEApp',
                'Invoice',inv,{Status:"TripAlloted"}).then((response)=>{
                  console.log("sucess")
                  
                }).catch((error)=>{console.log(error);
                toast.error("Error updating Invoice, Please try again later !!")
                })
              })
                toast.success("Trip Created Successfully !!")
                if(status=="TripAlloted"){
                 await axios.get(`https://panel.rapiwha.com/send_message.php?apikey=F6GM19T30HLHXGO2MEJL&number=91${driver==="Pradeep_Driver"?9109101363:9109101362}&text=Hey ${driver}, Trip ${tripNumber+" :: "+tripName} is alloted to you. Check your Trip Panel Dashboard`).then(function (response) {
                    toast.success("Message sent sucessfully !!")
                  }).catch(function (error){
                      toast.error('An error occurred while sendind Whatsapp Message !!')
                      console.log(error)
                  })
                }
                window.location.reload();
            }).catch((error)=>{
                toast.error("Error creating Trip, Please try again later !!");
        console.log(error);
            })
        
        }).catch((error)=>{
        toast.error("Error creating Trip, Please try again later !!");
        console.log(error);
        })
        }
        else{
        toast.error("Fields are empty, Please try again !!");
        }
    }
  }




  return (
    <div>
    <ToastContainer/>
        <div>
        <div className='flex flex-row justify-center flex-wrap'>
            <label className='m-2 mt-4 align-middle'>Trip No.</label>
            <input disabled={true} value={tripNumber} onChange={(e)=>{setTripNumber(e.target.value)}} className='m-2 w-24 sm:float-right max-sm:w-72 p-2 rounded-lg'></input>
            <label className='m-2 mt-4  align-middle'>Trip Name</label>
            <input value={tripName} onChange={(e)=>{setTripName(e.target.value)}} className='m-2 w-60 sm:float-right max-sm:w-72 p-2 rounded-lg'></input>
           
            <label className='m-2 mt-4  align-middle'>Trip Date</label>
            <input value={tripDate} onChange={(e)=>{setTripDate(e.target.value)}} type='date' className='m-2 w-36 sm:float-right max-sm:w-72 p-2 rounded-lg'></input>
            <label className='m-2 mt-4  align-middle'>Trip Time</label>
            <input value={tripTime} onChange={(e)=>{setTripTime(e.target.value)}} type='time' className='m-2  w-36 sm:float-right max-sm:w-72 p-2 rounded-lg'></input>
        </div>
        <div className='flex flex-row justify-center flex-wrap'>
        <label className='m-2 mt-4  align-middle'>Trip Driver</label>
            <select value={driver} onChange={(e)=>{setDriver(e.target.value);if(e.target.value!=="Select Driver"){setStatus("TripAlloted")}else{setStatus("TripCreated")}}} className='m-2  w-36 sm:float-right max-sm:w-72 p-2 rounded-lg'>
               {
                driverDropdown.map((driverDetails)=>{
                  return  <option value={driverDetails.driverName}> {driverDetails.driverName}</option>
                })
               }
            </select>
            <label className='m-2 mt-4  align-middle'>Trip Vehicle</label>
            <select value={vehicle} onChange={(e)=>{setVehicle(e.target.value)}} className='m-2  w-36 sm:float-right max-sm:w-72 p-2 rounded-lg'>
               <option value={"Select Vehicle"}>Select Vehicle</option>
               {
                vehicleDropdown.map((vehicleDetails)=>{
                  return  <option value={vehicleDetails.vehicleRegNo}> {vehicleDetails.vehicleRegNo}</option>
                })
               }
            </select> 
            <label className='m-2 mt-4  align-middle'>Trip Status</label>
            <select value={status} onChange={(e)=>{setStatus(e.target.value)}} className='m-2  w-36 sm:float-right max-sm:w-72 p-2 rounded-lg'>
               <option value={"TripCreated"}>{"TripCreated"}</option>
               <option value={"TripStarted"}>{"TripStarted"}</option>
               <option value={"TripEnded"}>{"TripEnded"}</option>
               <option value={"TripAlloted"}>{"TripAlloted"}</option>
               <option value={"TripCancelled"}>{"TripCancelled"}</option>
            </select> 
            
            </div>
            <div className='flex flex-row justify-center flex-wrap'>
            <label className='m-2 mt-4  align-middle'>PR. Nos.</label>
            <input  value={addedPrnos}  onChange={(e)=>{setAddedPrnos((data)=>e.target.value.split(" , "));console.log(addedPrnos)}} className='m-2  w-36 sm:float-right max-sm:w-72 p-2 rounded-lg'></input>
            <label className='m-2 mt-4  align-middle'>Inv. Nos.</label>
            <input  value={addedInvoices} onChange={(e)=>{setAddedInvoices((data)=>e.target.value.split(" , "));console.log(addedInvoices)}} className='m-2  w-36 sm:float-right max-sm:w-72 p-2 rounded-lg'></input>
            <label className='m-2 mt-4  align-middle'>WO. Nos.</label>
            <input  value={addedWorkOrders} onChange={(e)=>{setAddedWorkOrders((data)=>e.target.value.split(" , "));console.log(addedWorkOrders)}} className='m-2  w-36 sm:float-right max-sm:w-72 p-2 rounded-lg'></input>
            <button className='p-2 text-nowrap rounded-lg text-white float-right m-2 bg-blue-400 hover:bg-slate-200 border-2  hover:border-2 hover:border-blue-400 hover:text-black' onClick={handleSubmit}>Submit</button>

            </div>
            
           <div>
            <div className='overflow-x-scroll'>
                {/* Pickup Requests */}
                {prnos.length>0?
                <table className="table  w-full rounded-md   bg-blue-400 text-white">
                    <thead className='p-2 m-2'>
                        <th className='p-2'>Action</th>
                        <th className='p-2'>PR. NO.</th>
                        <th className='p-2'>Fleet Name</th>
                        <th className='p-2'>Pickup Site</th>
                        <th className='p-2'>Pickup Date & Pickup Time</th>
                        <th className='p-2'>Tyre Sizes</th>
                        <th className='p-2'>Tyre Quantity</th>
                    </thead>
                    <tbody>
                        {
                            prnos.map((prno)=>{
                                return(
                                    <tr key={prno.$id} id={prno.pickupRequestNumber} className='text-center border-t-2 bg-white text-black'>
                                        <td>{
                                            <>
                                            <button id={"addBtn"+prno.pickupRequestNumber} onClick={(e)=>{setAddedPrnos([...addedPrnos,prno.pickupRequestNumber]);setAddedPrnosId([...addedPrnosId,prno.$id]);console.log(addedPrnos);document.getElementById(prno.pickupRequestNumber).style.backgroundColor="#f7c2b0";document.getElementById("addBtn"+prno.pickupRequestNumber).style.visibility="hidden";document.getElementById("removeBtn"+prno.pickupRequestNumber).style.visibility="visible"}}><IoIosAddCircleOutline size={25} color='green' /></button>
                                        <button id={"removeBtn"+prno.pickupRequestNumber} style={{"visibility":"hidden"}} onClick={(e)=>{setAddedPrnos(itm=>itm.filter(item=>item!==prno.pickupRequestNumber));setAddedPrnosId(itm=>itm.filter(item=>item!==prno.$id));console.log(addedPrnos);document.getElementById(prno.pickupRequestNumber).style.backgroundColor="white";document.getElementById("addBtn"+prno.pickupRequestNumber).style.visibility="visible";document.getElementById("removeBtn"+prno.pickupRequestNumber).style.visibility="hidden"}}><VscDiscard size={25} color='red' /></button>
                                        </>
                                        }</td>
                                        <td>{prno.pickupRequestNumber}</td>
                                        <td>{prno.fleetName}</td>
                                        <td>{prno.pickupSite}</td>
                                        <td>{prno.pickupDate+" "+prno.pickupTime}</td>
                                        <td>{prno.tyreSizes?prno.tyreSizes.map((data)=>{return(data+" , ")}):""}</td>
                                        <td>{prno.tyreQuantity?prno.tyreQuantity.map((data)=>{return(data+" , ")}):""}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                :
                ""
                }
            </div>
            </div>
            <div>
                {/* Invoice */}
                <div className='overflow-x-scroll my-4'>
                
                {invoices.length>0?
                <table className="table  w-full rounded-md   bg-blue-400 text-white">
                    <thead className='p-2 m-2'>
                    <th className='p-2'>Action</th>
                        <th className='p-2'>INV. NO.</th>
                        <th className='p-2'>Fleet Name</th>
                        <th className='p-2'>Invoice Date</th>
                        <th className='p-2'>Tyre Sizes</th>
                        <th className='p-2'>Tyre Quantity</th>
                    </thead>
                    <tbody>
                        {
                            invoices.map((inv)=>{
                                
                                return(
                                   
                                    <tr key={inv.$id} id={inv.invNumber} className='text-center border-t-2 bg-white text-black'>
                                         <td>{
                                            <>
                                            <button id={"addBtn"+inv.invNumber} onClick={(e)=>{setAddedInvoices([...addedInvoices,inv.invNumber]);setAddedInvoicesId([...addedInvoicesId,inv.$id]);console.log(addedInvoices);document.getElementById(inv.invNumber).style.backgroundColor="#f7c2b0";document.getElementById("addBtn"+inv.invNumber).style.visibility="hidden";document.getElementById("removeBtn"+inv.invNumber).style.visibility="visible"}}><IoIosAddCircleOutline size={25} color='green' /></button>
                                        <button id={"removeBtn"+inv.invNumber} style={{"visibility":"hidden"}} onClick={(e)=>{setAddedInvoices(itm=>itm.filter(item=>item!==inv.invNumber));setAddedInvoicesId(itm=>itm.filter(item=>item!==inv.$id));console.log(addedInvoices);document.getElementById(inv.invNumber).style.backgroundColor="white";document.getElementById("addBtn"+inv.invNumber).style.visibility="visible";document.getElementById("removeBtn"+inv.invNumber).style.visibility="hidden"}}><VscDiscard size={25} color='red' /></button>
                                        </>
                                        }</td>
                                        <td>{inv.invNumber}</td>
                                        <td>{inv.fleetName}</td>
                                        
                                             <td>{inv.invDate}</td>
                                        <td>{inv.tyreSizes?inv.tyreSizes.map((data)=>{return(data+" , ")}):""}</td>
                                        <td>{inv.tyreQuantity?inv.tyreQuantity.map((data)=>{return(data+" , ")}):""}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                :
                ""
                }
            </div>
            </div>
            <div>
            <div className='overflow-x-scroll'>
                {/* Work Orders */}
                {workorderData.length>0?
                <table className="table  w-full rounded-md   bg-blue-400 text-white">
                    <thead className='p-2 m-2'>
                        <th className='p-2'>Action</th>
                        <th className='p-2'>WO. NO.</th>
                        <th className='p-2'>Fleet Name</th>
                        <th className='p-2'>Pickup Site</th>
                        <th className='p-2'>Date and Time</th>
                        <th className='p-2'>Created By</th>
                        <th className='p-2'>Created At</th>
                        
                    </thead>
                    <tbody>
                        {
                            workorderData.map((wono)=>{
                                return(
                                    <tr key={wono.$id} id={wono.workOrderNumber} className='text-center border-t-2 bg-white text-black'>
                                        <td>{
                                            <>
                                            <button id={"addBtn"+wono.workOrderNumber} onClick={(e)=>{setAddedWorkOrders([...addedWorkOrders,wono.workOrderNumber]);console.log(addedWorkOrders);document.getElementById(wono.workOrderNumber).style.backgroundColor="#f7c2b0";document.getElementById("addBtn"+wono.workOrderNumber).style.visibility="hidden";document.getElementById("removeBtn"+wono.workOrderNumber).style.visibility="visible"}}><IoIosAddCircleOutline size={25} color='green' /></button>
                                        <button id={"removeBtn"+wono.workOrderNumber} style={{"visibility":"hidden"}} onClick={(e)=>{setAddedWorkOrders(itm=>itm.filter(item=>item!==wono.workOrderNumber));console.log(addedWorkOrders);document.getElementById(wono.workOrderNumber).style.backgroundColor="white";document.getElementById("addBtn"+wono.workOrderNumber).style.visibility="visible";document.getElementById("removeBtn"+wono.workOrderNumber).style.visibility="hidden"}}><VscDiscard size={25} color='red' /></button>
                                        </>
                                        }</td>
                                        <td>{wono.workOrderNumber}</td>
                                        <td>{wono.fleetName}</td>
                                        <td>{wono.pickupSite}</td>
                                        <td>{wono.date+" "+wono.time}</td>
                                        <td>{wono.createdBy}</td>
                                        <td>{wono.createdAt}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                :
                ""
                }
            </div>
            </div>
            </div>
    </div>
    
  )
}


export default TripPanelCard